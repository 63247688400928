import React from "react"
import { Link } from "gatsby"
import LinkFix from "../../utils/links"

export default class extends React.Component {
  // 过滤非中文页面下的中文版号
  // get filteredLegalLinks () {
  //   const { settings, lang } = this.props
  //   return settings.legal_links.filter((item) => {
  //     if (Array.isArray(item.languages)) {
  //       return lang === 'en-us' ? item.languages.includes('default') : item.languages.includes(lang);
  //     }
  //     // 没有语言字段则返回所有
  //     return true;
  //   })
  // }

  render() {
    const { settings, lang } = this.props
    const year = new Date().getFullYear()

    return (
      <section className="footnotes">
        <div className="language-selector">
          <span className="language-icon"></span>

          <select className="languageSelectorFooter" onChange={(e) => { window.location = '/' + e.target.value }} value={lang} >
            <option value="" >English</option>
            <option value="de-de">Deutsch</option>
            <option value="fr-fr">Français</option>
            <option value="ja-jp">日本語</option>
            <option value="zh-cn">简体中文</option>
          </select>
        </div>
        <div className="legal legal-platform-mobile">
          <span className="legal-logo">© Hasselblad { year }</span>
        </div>
        <div className="legal legal-links-box">
          <div>
          { settings.legal_links.map((item, key) => {
            return (
              item.isCookiePreferencesBtn ? (
                <a className="cookie-preferences-btn" href="javascript:;">{item.text}</a>
              ) : (
                <Link to={ LinkFix(item.link) } key={key}>{item.text}</Link>
              )
            )
          })}
          </div>
        </div>
        <div className="legal legal-platform-pc">
          <span className="legal-logo">© Hasselblad { year }</span>
        </div>
      </section>
    )
  }
}
