import React from 'react'
import './footer.css'
import './cookiePopup.css'
import LazyLoad from 'react-lazyload';

export default class extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { settings, showCookiePopup, handleAcceptAll, handleRequireOnly, hideCookiePopup, acceptCookie } = this.props
    const {
      cookiePopup_headline,
      cookiePopup_content,
      cookiePopup_button1,
      cookiePopup_button2,
      cookie_notification
    } = settings
    const content_text =
      cookiePopup_content && cookiePopup_content.content[0].content[0].text

    const unnecessaryIcon = acceptCookie ? '/assets/icons/checked.svg' : '/assets/icons/unchecked.svg';

    return (
      <div
        id='cookie-popup'
        style={{ display: showCookiePopup ? 'block' : 'none' }}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5
                className='modal-title'
                dangerouslySetInnerHTML={{ __html: cookiePopup_headline }}
              />
              <button
                type='button'
                className='btn recording-section-cookie'
                onClick={hideCookiePopup}
              >
                <span className='icon'>
                  <LazyLoad offset={2000} once>
                  <img
                    src='/assets/icons/close-circle.svg'
                    alt='close-circle'
                  />
                  </LazyLoad>
                </span>
              </button>
            </div>
            <div className='modal-body'>
              <p
                className='body-content'
                dangerouslySetInnerHTML={{ __html: content_text }}
              />
              <ul className='modal-list'>
                {cookie_notification
                  ? cookie_notification.map((item) => (
                    <li className='modal-item' key={item.headline}>
                      <p
                        className='cookie-popup-headline'
                        dangerouslySetInnerHTML={{ __html: item.headline }}
                      />
                      <div>
                        <p
                          className='item-content'
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                      </div>
                      <div className='cookie-list-btn'>
                        <span className='icon'>
                        <LazyLoad offset={2000} once>
                          {item.isNecessaryCookies
                            ? (
                              <img
                                src='/assets/icons/checked.svg'
                                alt='checked'
                                className='necessary-cookies-icon'
                              />
                            )
                            : (
                              <img
                                src={unnecessaryIcon}
                                alt='unchecked'
                                className='unnecessary-cookies-icon'
                              />
                            )}
                        </LazyLoad>
                        </span>
                      </div>
                    </li>
                  ))
                  : null}
              </ul>
            </div>
            <div className='modal-footer'>
              <button
                className='btn cookie-btn cookie-required-only'
                onClick={handleRequireOnly}
                dangerouslySetInnerHTML={{ __html: cookiePopup_button1 }}
              />
              <button
                className='btn cookie-btn cookie-accept-all'
                onClick={handleAcceptAll}
                dangerouslySetInnerHTML={{ __html: cookiePopup_button2 }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
