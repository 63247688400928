import React from "react"
import LazyLoad from 'react-lazyload';

export default class extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
        showWechat : false
    }  
}

  render() {

    const { lang } = this.props;

    return (
      <>
      <LazyLoad offset={2000} once>
        <div className="social">
          <a href={ lang === 'ja-jp' ? 'https://www.facebook.com/Hasselblad.Japan' : 'https://www.facebook.com/Hasselblad' }>
            <img className="social__icon" src="/assets/icons/facebook.svg" alt="Facebook" />
          </a>
          <a href={ lang === 'ja-jp' ? 'https://www.instagram.com/hasselblad.japan/?hl=ja' : 'https://www.instagram.com/hasselblad' }>
            <img className="social__icon" src="/assets/icons/instagram.svg" alt="Instagram" />
          </a>
          
          <a href={ lang === 'ja-jp' ? 'https://twitter.com/Hasselblad_JPN' : 'https://twitter.com/Hasselblad' }>
            <img className="social__icon" src="/assets/icons/twitter.svg" alt="Twitter" />
          </a>            
      
          <a href="https://www.linkedin.com/company/hasselblad">
            <img className="social__icon" src="/assets/icons/linkedin.svg" alt="LinkedIn" />
          </a>
          { lang !== 'zh-cn' &&
          <a href="https://www.youtube.com/user/HasselbladAS">
            <img className="social__icon" src="/assets/icons/youtube.svg" alt="Youtube" />
          </a>
          }
          <a href="https://www.weibo.com/hasselbladofficial">
            <img className="social__icon" src="/assets/icons/weibo.svg" alt="Weibo" />
          </a>
          { lang === 'zh-cn' &&
          <a onClick={ (e) =>  this.setState({ showWechat : true })} >
            <img className="social__icon" src="/assets/icons/wechat.svg" alt="Wechat" />
          </a> 
          }
        </div>
      </LazyLoad>
        { lang === 'zh-cn' && this.state.showWechat &&
        <LazyLoad offset={2000} once>
          <div className="social wechat-qr"  >
            <img src="/assets/icons/wechat-qr.jpg" alt="Wechat" />
          </div>
        </LazyLoad>
        }
      </>
    )
  }
}
