import React from "react"
import SbEditable from "storyblok-react"
import { Link } from "gatsby"
import LinkFix from "../../utils/links"

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sectionActiveIndex: -1,
      sectionMaxHeightMap: {},
      maxHeightIsSetMap: {},
    }
  }
  render() {
    const { navigation, lang } = this.props;
    const { sectionActiveIndex, sectionMaxHeightMap, maxHeightIsSetMap } = this.state;
    const language = (lang === 'en-us' ? 'default' : lang);

    return (
      <nav role="navigation" className="footer-wrapper">
        <div className="footer__menu">
          <SbEditable content={navigation}>
            {navigation.map((section, section_key) => {
              return (
                <div className="footer__menu__section" key={section_key} style={section.sectionMarginTop ? { marginTop: section.sectionMarginTop } : {}}>
                  <Link to={ LinkFix(section.heading[0].link) }>
                    <h1>{section.heading[0].text}</h1>
                    <span
                      className={`footer__menu__section__toggle ${sectionActiveIndex === section_key ? 'footer__menu__section__toggle_active' : ''}`}
                      onClick={(e) => { e.preventDefault(); this.setState((prevState) => ({ sectionActiveIndex: prevState.sectionActiveIndex === section_key ? -1 : section_key })) }}
                    >
                      <span className="footer__menu__section__toggle__button"/>
                    </span>
                  </Link>
                  <div
                    className={`footer__menu__section__list`}
                    style={sectionActiveIndex === section_key ? { maxHeight: `${sectionMaxHeightMap[section_key]}px`, opacity: 1 } : { maxHeight: 0, opacity: 0 }}
                  >
                    <div
                      className={`footer__menu__section__list_box`}
                      ref={(ele) => {
                        if (ele && ele.clientHeight && ele.clientHeight !== sectionMaxHeightMap[section_key]) {
                          maxHeightIsSetMap[section_key] = true;
                          sectionMaxHeightMap[section_key] = ele.clientHeight;
                        }
                      }}
                    >
                      {section.groups.map((group, group_key) => group.heading.length > 0 && group.heading[0].link && (!group.heading[0].languages || (group.heading[0].languages && group.heading[0].languages.includes(language))) ? (
                        <ul key={group_key}>
                          <li key={group_key + `-0`}>
                            <Link
                              className="group"
                              to={ LinkFix(group.heading[0].link) }
                            >
                              {group.heading[0].text}
                            </Link>
                          </li>
                        </ul>
                      ) : null
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </SbEditable>
        </div>
      </nav>
    )
  }
}
