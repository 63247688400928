export const hasWindow = typeof window !== 'undefined';
export function DOMReady(fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

// 判断是否是IOS手机
export function isIOS() {
  if (typeof window === 'undefined') {
    return false;
  }
  const ua = window.navigator.userAgent;
  const ipad = /(iPad).*OS\s([\d_]+)/.test(ua)
  const iphone = !ipad && /(iPhone\sOS)\s([\d_]+)/.test(ua)
  const android = /(Android);?[\s/]+([\d.]+)?/.test(ua)
  const ios = iphone || ipad
  return {ios, android}
}

export function  isFirBox() {
  if (typeof window === 'undefined') {
    return false;
  }
  const ua = window.navigator.userAgent;
  return !!ua.match(/Firefox/ig);
}

// 是否为safari
export function checkIsSafari() {
  if (!hasWindow) {
    return;
  }
  const ua = window.navigator.userAgent;
  return /Safari/i.test(ua) && !/Chrome/i.test(ua);
}