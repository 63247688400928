import React from "react"
import "./newsletter.css"
import API from "../../utils/api"
import marked from 'marked'
import t from "../../utils/languages"

export default class extends React.Component {


    constructor(props) {
        super(props);
        this.settings = props.settings;
        this.lang = props.lang;
        //console.log(query)
        this.state = {
            email : "",
            country : "",
            interest : 'Inspiration',
            countries: [],
            errors:{
                email: false
            },
            submitted : false,
            isSubmittedScussce:true,
  		    isLoad: false,
  		    timeout: true,
        }  
    }
  
    componentDidMount = () => {
        API.get("/countries")
        .then(result => {
            this.setState({ countries: result.data.countries, country : result.data.userCountry })
        })
        .catch(err => {
            console.log(err)
        })
    }
      
    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        let errors = this.state.errors;
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        switch (name){
            case 'email':
                errors.email = !emailRegex.test(value);
                break;
            default:
                break;
        }
        this.setState({
            errors,
            [name]: value,
        })
    }

    submit = event => {
        let _this=this;
  		this.setState({isLoad:true})
        const params = {};
        params.country = this.state.country;
        params.email = this.state.email;
        params.interest = this.state.interest;
        API.post("/newsletter/subscribe", params)
            .then(result => {
                this.setState({
                    submitted: true,
                    isSubmittedScussce:true,
                    isLoad:false,
                    timeout:false
                })
                setTimeout(()=>{
                    _this.setState({timeout:true})
                },3000)        
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    submitted: false,
                    isSubmittedScussce:false,
                    isLoad:false,
                    timeout:false
                  })
                  setTimeout(()=>{
                      _this.setState({timeout:true})
                  },3000)
            })
    }

  render() {
    const {isSubmittedScussce,isLoad,timeout}=this.state;
    return (
        
        <section className="footer__newsletter center">

            <div className="fnl__wrapper">
                <div className="newsletter_content" style={this.state.submitted ? { display : 'none'} : null } dangerouslySetInnerHTML={{ __html: marked(this.settings.newsletter_copy) }} />
                <div className="newsletter_content" style={!this.state.submitted ? { display : 'none'} : null } dangerouslySetInnerHTML={{ __html: marked(this.settings.newsletter_thank) }} />
                
                <label htmlFor="newsletterField">
                <div style={{ maxWidth:'325px'}}>
                    <span className="show"> 
                        { t('newsletter.emailPlaceHolder', this.lang)}
                    </span>
                </div>
                <input id="newsletterField" name="email" maxLength='255' className={this.state.errors.email ? "error" : null} type="email" placeholder={ t('newsletter.emailPlaceHolder', this.lang)} value={this.state.email} onChange={this.handleInputChange} style={{ maxWidth:'325px' }} />
                <div style={{ maxWidth:'325px', marginTop: '8px', opacity: this.state.errors.email ? 1 : 0 }}>
                    <span className="error show">{ t('newsletter.emailInvalid', this.lang) }</span>
                </div>
                </label>
                <label htmlFor="country">
                    <div style={{ maxWidth:'325px' }}>{ t('newsletter.selectYourCountry', this.lang) }</div> 
                    <select id="country" value={this.state.country} onBlur={(e) => this.setState({country: e.target.value})} onChange={(e) => this.setState({country: e.target.value}) } style={{ maxWidth:'325px',paddingRight:0}} >
                        {this.state.countries.map((c, index) => <option key={index} value={c.id}>{c.t}</option>)}
                    </select>
                </label> 
                <div className="cta-row">
                <button className={`${this.state.errors.email || isLoad || this.state.email.length === 0 ? 'disabled cta' : 'cta' } ${isLoad?"loading": null}`} disabled={this.state.errors.email || isLoad || this.state.email.length === 0} onClick={this.submit}>{t('newsletter.subscribe', this.lang)}</button>
  		        </div>
  		        {/* 添加错误提示的信息 */}
  		        <div className={`newsletter-message-content ${!timeout ? 'newsletter-message-show' : ''}`}>
  		        <span className={isSubmittedScussce?"scussed":"filed"}>{isSubmittedScussce ? t('submit.scussce',this.lang):t('submit.failed',this.lang)}</span>
                </div>
            </div>
      </section>
    )
  }
}
