import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./header";
import "./layout.css";
import Footer from "./footer";

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query SettingsQuery {
      allStoryblokEntry(filter: { name: { eq: "settings" } }) {
        nodes {
          content
          lang
        }
      }
    }
  `);

  const multiSettings = data.allStoryblokEntry;
  let allSettings = {}; 
  for (let index = 0; index < multiSettings.nodes.length; index++) {
    let s = multiSettings.nodes[index];
    allSettings[s.lang === "default" ? "en-us" : s.lang] = JSON.parse(
      s.content
    );
  }
  // console.log(props)
  const { page, children } = props;
  const seo = page.blok.seo || {};
  const lang = page.lang === "default" ? "en-us" : page.lang;
  let settings = allSettings[lang];
  let title =
    page.blok.title && page.blok.title.trim().length > 0
      ? page.blok.title
      : page.name;
  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined";
  let host = "";
  if (isBrowser) {
    host = window.location.host;
  }

  const env = host === 'www.hasselblad.com' ? 'PRODUCTION' : 'DEV';
  const environment = process.env.GATSBY_HASSELBLAD_ENV;

  let cookieLawCountryCode = ['en-us', 'de-de','fr-fr'];
  const isLawCountry = cookieLawCountryCode.indexOf(lang) !== -1;

  const {
    appId = 'hasselblad',
    loggerEnabled = false,
    loggerUrl = '',
    services = "[\"www.googletagmanager.com/gtm.js\"]",
    cookies = "[{name: /^_g/,domain: \".hasselblad.com\"}]",
  } = settings.ccpa_config && settings.ccpa_config[0] || {};
  const armsEnvironment = process.env.GATSBY_ARMS_ENV
  const imgUrl = page.lang === "zh-cn" ? "https://arms-retcode.aliyuncs.com/r.png?": "https://retcode-us-west-1.arms.aliyuncs.com/r.png?";
  const ignore = {
    "ignoreUrls": ["//localhost"]
  }
  const blCongig = {
      appType:"web",
      sendResource:true,
      enableLinkTrace:true,
      behavior:true,
      armsEnvironment,
      useFmp:true,
      pid: page.lang === "zh-cn" ? "ififn93np0@47bda3e449278c1" : "ififn93np0@0c8f2ddb3153662",
      imgUrl,
      ignore,
      enableSPA:true,
  }
  
  const isCN = lang === 'zh-cn';

  const slsConfig = {
    script: process.env.GATSBY_ONEWEBPPLUGIN_SCRIPT,
    host: isCN? process.env.GATSBY_ONEWEBPPLUGIN_SLS_HOST_CN: process.env.GATSBY_ONEWEBPPLUGIN_SLS_HOST,
    project: isCN ? process.env.GATSBY_ONEWEBPPLUGIN_SLS_PROJECT_CN: process.env.GATSBY_ONEWEBPPLUGIN_SLS_PROJECT,

  }
  return (
    <>
      <Helmet
        title={title}
        meta={[
          {
            name: `description`,
            content: seo.description,
          },
          {
            property: `og:url`,
            content: `${host}${
              page.path.endsWith("/home")
                ? page.path.replace("/home", "/")
                : page.path
            }`,
          },
          {
            property: `og:title`,
            content: seo.og_title || title,
          },
          {
            property: `og:description`,
            content: seo.og_description || seo.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: seo.og_image,
          },
          {
            property: `twitter:image`,
            content: seo.og_image,
          },
          {
            name: `twitter:card`,
            content: `photo`,
          },
          {
            name: `twitter:creator`,
            content: lang === "ja-jp" ? "@Hasselblad_JPN" : "@hasselblad",
          },
          {
            name: `twitter:title`,
            content:
              seo.twitter_title ||
              seo.og_title ||
              seo.title ||
              page.blok.title ||
              page.name,
          },
          {
            name: `twitter:description`,
            content:
              seo.twitter_description || seo.og_description || seo.description,
          },
          {
            name: "baidu-site-verification",
            content: "XrploVYPo1",
          },
          { 
            name: "viewport",
            content: "width=device-width,initial-scale=1,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no,shrink-to-fit=no"
          },
          { 
            name: "apple-mobile-web-app-capable",
            content: "yes"
          },
        ]}
      >
        <html lang={lang} />
        {}
        <link rel="dns-prefetch" href="https://cdn.hasselblad.com/" />
        <link rel="preconnect" href="https://cdn.hasselblad.com/" crossorigin />
        <link
          rel="canonical"
          href={`${host}${
            page.path.endsWith("/home")
              ? page.path.replace("/home", "/")
              : page.path
          }`}
        />
        <link
          rel="preload"
          href="/assets/fonts/ed4b9060-b5ab-4379-8840-0b50a15258b7.woff2"
          as="font"
          type="font/woff2"
          crossorigin="anonymous"
        />
        <link
          rel="preload"
          href="/assets/fonts/6630f703-611d-4ffb-9b65-f9ba9ab9bc0a.woff2"
          as="font"
          type="font/woff2"
          crossorigin="anonymous"
        />
        <link rel="icon" href="/favicon.ico" type="image/x-icon"></link>
        {
          isLawCountry ? (
            <script src="/assets/js/scio.umd.js" crossOrigin="anonymous"></script>
          ) : ''
        }

        <script>
          {
            `
            function loadExternalScript(src, callback) {
               let script = document.createElement('script');
               script.type = 'text/javascript';
               script.src = src;

               script.onload = () => callback(script);
               document && document.head.append(script);
             }
            `
          }
        </script>
        <script>
          {
            `
              function sentryCB() {
                Sentry.init({
                  dsn: 'https://bb1bd153de45498bb7d9c773b37c2377@sentry-io.djiops.com/503',
                  environment: "${environment}",
                });
              }
              loadExternalScript('https://static.djicdn.com/sentry-sdk/sentry-javascript-latest.min.js', sentryCB);
            `
          }
        </script>

        {
          isLawCountry ? (
            <script>
              {
                `
                if (typeof Scio === 'function') {
                  var scio = new Scio({
                    language: '',
                    country: '',
                    loggerEnabled: ${loggerEnabled},
                    loggerUrl: '${loggerUrl}',
                    appId: '${appId}',
                    environment: '${env}',
                    cookieName: 'hasselblad_cookie_consentmanager',
                    services: ${services},
                    cookies: ${cookies},
                    privacyPolicy: 'javascript:;',
                    locale: {
                      en: {
                        privacyPolicy: '&nbsp;',
                        cookiePreferences: '&nbsp;',
                        acceptAll: '&nbsp;',
                        requiredOnly: '&nbsp;',
                        bar: {
                          title: '&nbsp;',
                          desc: '&nbsp;',
                        },
                        modal: {
                          desc: '&nbsp;',
                        },
                      },
                    },
                    categories: {
                      necessary: {
                        necessary: true,
                        locale: {
                          en: {
                            title: '&nbsp;',
                            desc: '&nbsp;',
                          },
                        },
                      },
                      preference: {
                        locale: {
                          en: {
                            title: '&nbsp;',
                            desc: '&nbsp;',
                          },
                        },
                      },
                      statistics: {
                        locale: {
                          en: {
                            title: '&nbsp;',
                            desc: '&nbsp;',
                          },
                        },
                      },
                      marketing: {
                        locale: {
                          en: {
                            title: '&nbsp;',
                            desc: '&nbsp;',
                          },
                        },
                      },
                    },
                  });
                }`
              }
            </script>
          ) : ''
        }
        
        {slsConfig.script && <script>
          {`
            function slsLoaded(script){
              script.setAttribute("data-sls-sampling-rate", 0.2);
              script.setAttribute("data-perf-ping-speed",true);
              script.setAttribute("data-sls-jank-rate-sampling-rate", ${environment === 'prod'? 0.1 : 1});

              window.sls = window.sls || [];
              function slsCall() { window.sls.push(arguments) };
              slsCall('initSls', {
                host: '${slsConfig.host}',
                project: '${slsConfig.project}',
                logstore: 'dji-official-web'
              });
            }
            loadExternalScript('${slsConfig.script}', slsLoaded);
          `}
        </script>}
      </Helmet>
      {
        <script
        dangerouslySetInnerHTML={{
          __html:`!(function(c,b,d,a){c[a]||(c[a]={});c[a].config=${JSON.stringify(blCongig)};
          with(b)with(body)with(insertBefore(createElement("script"),firstChild)){setAttribute("crossorigin","",src=d,type='text/javascript',async=true),addEventListener('load', function (e) {
                
          })}
          })(window,document,"https://retcode.alicdn.com/retcode/bl.js","__bl");`
        }}
        ></script>
      }
      <Header
        settings={settings}
        lang={lang}
        path={page.path}
        name={page.name}
        page={page}
      />
      <main>{children}</main>
      <Footer settings={settings} lang={lang}/>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
