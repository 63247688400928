import Axios from "axios"
import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
  maxAge: 15 * 60 * 1000
})

const instance = Axios.create({
    baseURL: process.env.GATSBY_HASSELBLAD_API_URL,
    timeout: 10000,
    adapter: cache.adapter
  });

export default instance