const en_us =  require('./locales/en-us/labels.json')
const fr_fr = require('./locales/fr-fr/labels.json')
const de_de = require('./locales/de-de/labels.json')
const ja_jp = require('./locales/ja-jp/labels.json')
const zh_cn = require('./locales/zh-cn/labels.json')

const t = (key, lang) => {

    const langs = {
        'default': en_us,
        'fr-fr' : fr_fr,
        'de-de' : de_de,
        'ja-jp' : ja_jp,
        'zh-cn' : zh_cn
    };

    if (langs[lang] && langs[lang][key]){
        return langs[lang][key];
    }
    else if (langs['default'][key]){
        return langs['default'][key]
    }
    else{
        return 'Missing translation ' + lang + ' - ' + key
    }
}

export default t
