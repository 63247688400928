import React from "react"
import { Link } from "gatsby"
import _throttle from 'lodash.throttle';
import "./header.scss"
import LinkFix from "../../utils/links"
import t from "../../utils/languages"

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      localMenuVisible: false
    }
    this.tabDom = [];
    this.tabBar = React.createRef();
    this.getCenter = this.getCenter.bind(this);
  }

  componentDidMount(){
    var _this=this
    document.body.classList.remove('touchDisable');
    var navbar = document.getElementById("header");
    var footer = document.getElementById("footer");
    var nav_wrapper = document.getElementById("gatsby-focus-wrapper");
  	navbar.classList.add('header_sticky');
  	nav_wrapper.classList.add('header_wrap');
    const targetDom = document.body
    const obj = targetDom.getBoundingClientRect();
    // Get the offset position of the navbar
    const sticky = 60;
    window.onscroll = _throttle(() => {
      const windowTop = window.scrollY || window.pageYOffset;
      if (windowTop >= sticky) {
        navbar.classList.add("sticky");
        // 当屏幕超过768px、滚动到footer底部且菜单展开时，关闭菜单
        if (windowTop >= footer.offsetTop && obj.width >= 768 && _this.state.localMenuVisible) {
          _this.setState({ localMenuVisible: false });
        }
      } else {
        navbar.classList.remove("sticky");
      }
    }, 200);

    // 获取选中tab，如果有，则滚动居中
    const activeTab = document.querySelector("a.ln__button.active");
    if (activeTab) this.getCenter(activeTab);
  }

  toggleClass() {
    const { active, localMenuVisible} = this.state;
    this.setState({ active: !active })
    if (!active) {
      document.body.classList.add('menu-opened');
      document.querySelector('html').classList.add('menu-opened');
    } else if (active && !localMenuVisible) {
      document.body.classList.remove('menu-opened');
      document.querySelector('html').classList.remove('menu-opened');
    }
  }

  toggleLocalMenu(){
    var isM = 768 > document.body.getBoundingClientRect().width
    this.setState({ localMenuVisible: !this.state.localMenuVisible })
    if (!this.state.localMenuVisible){
      if(isM){
        document.body.style.overflow = 'hidden';
        document.body.classList.add('touchDisable');
      }
    }
    else{
      document.body.style.overflow = 'auto';
      document.body.classList.remove('touchDisable');
      document.querySelector('html').classList.remove('menu-opened');
      document.body.classList.remove('menu-opened');
    }
  }
  getPageNavigation(path, settings){
    for (let i = 0; i < settings.footer_navigation.length; i++) {
      let element = settings.footer_navigation[i];
      for (let g = 0; g < element.groups.length; g++) {
        let group = element.groups[g];

        for (let l = 0; l < group.links.length; l++) {
          let link = group.links[l];
          const cached_url = link.link.cached_url[0] === '/' ? link.link.cached_url : '/' + link.link.cached_url
          // 获取产品页二级导航下拉菜单选中项，返回选中项文案，用于展示二级导航标题
          if (cached_url === path || path.startsWith(LinkFix(link.link))) return { group, element, link };
        }

        let link = group.heading[0];
        //if (!group.heading[0]) console.log(group);
        if (group.heading[0] && (group.heading[0].link.cached_url === path || path.startsWith(LinkFix(group.heading[0].link)))) {
          // 判断 path 是否有相关标题
          return { group, element, link };
        }
      }
    }
    return {};
  }

  /**
   *  tab滚动居中
   * @param {element} dom 选中元素
   */
  getCenter(dom) {
    const wrapperWidth = document.getElementById('___gatsby').clientWidth; // 页面内容宽度

    if (!dom || wrapperWidth >= 768) return; // pc端不需要执行

    const domoffsetWidth = dom.offsetLeft; // item当前位置
    const targetOffset = domoffsetWidth - wrapperWidth / 2 + dom.offsetWidth / 2; // 目标值 = item左间距 - 页面内容中轴 + item宽度的一半
    const tabBarDom = this.tabBar;

    tabBarDom.scroll({ left: targetOffset, behavior: 'smooth' });
  }

  render() {
    const { settings, lang, path, page } = this.props;
    const { store_link, store_text } = settings;
    const { active } = this.state;
    const nav = settings.header_navigation;
    let localNavigation = null; 
    let localNavigationSection = null; 
    let segments = path.split('/').filter((s) => {
      return s !== null && s !== '' && s !== lang
    })

    let { group, element, link } = this.getPageNavigation(path[0] === '/' ? path : '/' + path, settings)
    if (segments.length > 1){
      localNavigation = group;
      localNavigationSection = element;
    } 

    const headerClassName = page.menu_transparency ? localNavigation ? 'transparent has-subnav' : 'transparent' : localNavigation ? 'has-subnav' : '';
    return (
      <header id="header" className={headerClassName}>
        <nav id="global-navigation" role="navigation">
          <div className={`nav__wrapper ${active ? "show" : ""}`}>
            <div className="nav-header">
              <Link to={ lang === 'en-us' ? '/' : '/' + lang + '/' } className="logo">
              </Link>
              <ul className="gn__menu__small">
                <li>
                  <button onClick={() => this.toggleClass()} >
                    <img className="menu-icon" src="/assets/icons/menu-white.svg" alt="Menu" />
                    <img className="close-icon" src="/assets/icons/close-icon-white.svg" alt="close" />
                  </button>
                </li>
              </ul>
            </div>
            <div className="gn__menu_wrapper gn__menu_wrapper_middle">
              <div className="gn__menu">
                <ul className="gn_menu_body">
                  { nav.map((item, item_key) => {
                    if (item.link && item.link.cached_url){
                      return(<li key={item_key}>
                        <Link to={ LinkFix(item.link)}>
                          <h1>{item.text}</h1>
                        </Link>
                      </li>)
                        
                    }
                    else{
                      return ''
                    }
                  })}
                   {
  		                store_link && store_text &&
  		                <>
  		                <li className="ln_store_line"></li>
  		                <li className="ln_store_M">
  		                <Link
  		                to={LinkFix(store_link)}
  		                target="_blank"
  		                ><h1>{store_text}</h1></Link>
  		                </li>
  		              </>
  		            }
                </ul>
              </div>
            </div>
            <div className="gn__menu_wrapper gn__menu_wrapper_last">
              <div className="gn__menu">
                <ul className="gn__menu__aux">
                  <li>
                    <Link to={lang === 'en-us' ? '/my-hasselblad/' : '/' + lang + '/my-hasselblad/'}>
                      <img src="/assets/icons/profile-white.svg" alt={ t('profile.menu.myHasselblad', lang)  } />
                      <h2>{ t('profile.menu.myHasselblad', lang) }</h2>
                    </Link>
                  </li>
                </ul>
                {
  		            store_link && store_text &&
  		            <Link className="ln__store"
  		            target="_blank"
  		            to={LinkFix(store_link)}
  		            >{store_text}</Link>
  		          }
              </div>
            </div>
          </div>
        </nav>
        { localNavigation &&
        <nav id="local-navigation" className="local-navigation" role="navigation">
            <div className={`ln__bar ${page.blok.menu_cta && page.blok.menu_cta.length > 1 ? 'double_menu' : ''}`} >
              <div className="ln__wrapper">
                <div className={`ln__heading ${this.state.localMenuVisible ? 'open' : '' }` } onClick={() => this.toggleLocalMenu()}>
                <a>{link.text}</a>
                </div>
                {page.blok.menu_cta && page.blok.menu_cta.length > 0 && page.blok.menu_cta.map(
                  (item, index) => (
                    item.buttons && item.buttons.length > 0 ?
                      (<>
                        <div className="ln__line"></div>
                        <div className="ln__row" ref={(c) => { this.tabBar = c; }} key={index}>
                          <ul key={index}>
                          {item.buttons.map(
                            (btn, bIndex) => (
                              btn.link_type === 'external_link' ? (
                               <li key={bIndex}> <a className="ln__button" href={LinkFix(btn.link)} target="_blank" ref='subBtton'>{btn.text}</a></li>
                              ) : (
                                <li key={bIndex}>
                                <Link
                                  className={`ln__button${`${path}/` === (LinkFix(btn.link)) ? ' active' : ''}`}
                                  to={LinkFix(btn.link)}
                                  ref={(c) => { this.tabDom[bIndex] = c; }}
                                >
                                  {btn.text}
                                </Link>
                                </li>
                              )
                            )
                          )}
                          </ul>
                        </div>
                      </>) : (
                        item.text && <Link key={item} ref='subBtton' to={LinkFix(item.link)} className="ln__cta">{item.text}</Link>
                      )
                  )
                )}
              </div>
            </div>
            <nav className={`ln__menu ${this.state.localMenuVisible ? 'show' : '' } `}>
              <ul>
                { localNavigationSection.heading.map((item, hIndex) => <li key={'s-' + hIndex} ><Link  activeClassName="current" to={ LinkFix(item.link) }>{item.text}</Link></li>) }
                { localNavigation.heading.map((item, hIndex) => <li key={'h-' + hIndex} ><Link  activeClassName="current" to={ LinkFix(item.link) }>{item.text}</Link></li>) }
                { localNavigation.links.map((item, lIndex) => <li key={'l-' + lIndex}><Link partiallyActive={true} activeClassName="current" to={ LinkFix(item.link) }>{item.text}</Link></li>) }
              </ul>
            </nav>
          </nav>
          }
      </header>

    )
  }
}
