import React from "react"
import FooterMenu from "./footerMenu"
import Social from "./social"
import Footnotes from "./footnotes"
import NewsletterSignup from "./newsletter"
import CookiePopup from "./cookiePopup"
import "./footer.css"
import { getCookie } from "../../utils/cookies"
import { DOMReady } from '../../../helper/utils'
 

const hasWindow = typeof window !== 'undefined';
const hasDocument = typeof document !== 'undefined';

export default class extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      showCookiePopup : false,
      cookieExists: false,
      showCookieBar: false,
      acceptCookie: false, // 是否选择了accept all
    }
}

  componentDidMount = () => {
    const { lang } = this.props;
    let cookie = getCookie('hasselblad_cookie_consentmanager');
    var cookieLawCountryCode = ['en-us', 'de-de','fr-fr'];
        
    if (!hasWindow || !hasDocument) return;

    const isEditor = typeof window.storyblok !== 'undefined';
    const isScioInit = typeof window.scio !== 'undefined';

    this.setState({
      cookieExists: cookie !== '',
      showCookieBar: cookieLawCountryCode.indexOf(lang) !== -1 && (isEditor || isScioInit),
      acceptCookie: isScioInit && window.scio.options && window.scio.options.acceptCookie,
    })

    // 监听按钮点击 打开弹窗
    this.handlePopUpBtnClick();
    
    // scio没初始化 隐藏入口
    this.hidePopBtn(cookieLawCountryCode, lang, isEditor, isScioInit);
    
    // 监听状态变化
    if (isScioInit) {
      window.scio.on('statusChanged', this.handleStatusChange)
    }
  }

  handleStatusChange = () => {
    this.setState({
      acceptCookie: window.scio.options.acceptCookie
    })
  }

  handlePopUpBtnClick = () => {
    document.body.addEventListener('click', e => {
      let el = e && e.target;
      if (!el) {
        return;
      }
      while (!el.classList || !el.classList.contains('cookie-preferences-btn')) {
        if (!el.parentNode || el === document.body) {
          el = null;
          break;
        }
        el = el.parentNode;
      }
      if (el) {
        e && e.preventDefault();
        this.showCookiePopup();
      }
    });
  }

  hidePopBtn = (cookieLawCountryCode, lang, isEditor, isScioInit) => {
    if (cookieLawCountryCode.indexOf(lang) === -1 || (!isScioInit && !isEditor)) {
      DOMReady(() => {
        const actions = document.querySelectorAll('.cookie-preferences-btn');
        Array.prototype.forEach.call(actions, action => {
          action.style.display = 'none';
        });
      });
    }
  }

  handleEleClick = (selector) => {
    const ele = document.querySelector(selector);
    if (ele) {
      ele.click();
    }
  }

  hideCookieBar = (loggerEnabled = true) => {
    this.setState({ showCookieBar : false })
    // 调用scio关闭bar的方法 埋点
    loggerEnabled && this.handleEleClick('.cc-window.cc-bar > div > div.cc-header > a');
  }

  showCookiePopup = () => {
    this.setState({
      showCookiePopup : true,
    });
    document.body.classList.add('modal-open');
    // 调用scio打开弹窗的方法 埋点
    this.handleEleClick('.cc-window.cc-bar > div > div.cc-content > div.cc-footer > button.cc-consent-prefernces')
  }

  hideCookiePopup = (loggerEnabled = true) => {
    this.setState({ showCookiePopup : false });
    document.body.classList.remove('modal-open');
    // 调用scio关闭弹窗的方法 埋点
    loggerEnabled && this.handleEleClick('.cc-window.cc-modal > div.cc-dialog > div.cc-header > a');
  }

  handleAcceptAll = () => {
    this.hideCookieBar(false);
    this.hideCookiePopup(false);
    // 调用scio accept all方法
    this.handleEleClick('.cc-window.cc-bar > div > div.cc-content > div.cc-footer > .cc-consent-accept')
  }

  handleRequireOnly = () => {
    const acceptCookie = window.scio.options.acceptCookie;
    // 隐藏 bar、弹窗
    this.hideCookieBar(false);
    this.hideCookiePopup(false);
    // 调用scio require only方法
    this.handleEleClick('.cc-window.cc-modal > div.cc-dialog > div.cc-footer > .cc-btn.cc-js-modal-submit');
    // 判断点击require only之前 用户有没有点击accept all
    if (acceptCookie) {
      window.location.reload()
    } 
  }

  handleBtnClick = (type) => {
    switch (type) {
      case 'popup':
        this.showCookiePopup()
        break;
      case 'accept-all':
        this.handleAcceptAll();
        break;
      case 'require-only':
      default:
        this.handleRequireOnly();
        break;
    }
  }

  formatContent = (template, context) => {
    return template && template.replace(/\{\{([\w\d]+)\}\}/g, (match, key) => context[key]);
  }

  link() {
    const { settings, lang } = this.props;
    const cookiePolicyLink = lang === 'en-us' ? '/legal/cookie-policy/' : `/${lang}/legal/cookie-policy/`
    return {
      cookiePreferencesText: `<a class="cookie-preferences-btn" href="javascript:;">${settings.cookiePreferencesText}</a>`,
      privacyPolicyText: `<a href=${cookiePolicyLink} target="_blank">${settings.privacyPolicyText}</a>`,
    };
  }

  render() {
    const { settings, lang } = this.props
    const {
      cookieNotification_content,
      cookieNotification_button1,
      cookieNotification_button1_type = 'require-only',
      cookieNotification_button2,
      cookieNotification_button2_type = 'accept-all',
    } = settings || {};
    const content_text =
      cookieNotification_content &&
      cookieNotification_content.content[0].content[0].text;

    const { cookieExists, showCookieBar, showCookiePopup, acceptCookie } = this.state;

    return (
      <footer id="footer">
        <NewsletterSignup settings={settings} lang={lang} />
        <Social settings={settings} lang={lang} />
        <FooterMenu navigation={settings.footer_navigation} lang={lang} />
        <Footnotes settings={settings} lang={lang} />
        {
          showCookieBar && !cookieExists && (
            <section className="cookies cookie-bar">
              <div className="cookies-wrapper">
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: this.formatContent(content_text, this.link()) }}
                />
                <div className="cookie-bar-btn-wrap">
                  <div className="flex">
                    <button
                      className="cookie-popup-entrance cookie-bar-btn"
                      onClick={() => this.handleBtnClick(cookieNotification_button1_type)}
                      dangerouslySetInnerHTML={{
                        __html: cookieNotification_button1,
                      }}
                    />
                    <button
                      className="cookie-accept-all cookie-bar-btn"
                      onClick={() => this.handleBtnClick(cookieNotification_button2_type)}
                      dangerouslySetInnerHTML={{
                        __html: cookieNotification_button2,
                      }}
                    />
                  </div>
                  <button className="close" onClick={this.hideCookieBar}>
                    <img src="/assets/icons/close-white.svg" alt="close" />
                  </button>
                </div>
              </div>
            </section>
          )
        }
        <CookiePopup
          settings={settings}
          lang={lang}
          handleAcceptAll={this.handleAcceptAll}
          handleRequireOnly={this.handleRequireOnly}
          showCookiePopup={showCookiePopup}
          hideCookiePopup={this.hideCookiePopup}
          acceptCookie={acceptCookie}
        />
      </footer>
    )
  }
}
