const cachedUrlFix = (link) => {
    
    let url = link?.cached_url
    //check for trailing /, if not add it
    if (url && url.length > 0 && url.charAt(url.length -1) !== '/'){
        url = url + '/'
    }

    if (link?.linktype === 'url'){
        return link?.url
    }
    if (url && url.length > 0 && url[0] === '/'){
        return url
    }
    else {
        return '/' + url;
    }
}

export default cachedUrlFix